<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <div class="helpbox">
            <div class="helpcon">
                <div class="clear"></div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"><i class="iconfont">&#xe619;</i>首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="listordetail==0">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item v-else :to="{ path: '/refresh' }">帮助中心</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="listordetail==1">{{DetailData.HelpTitle}}</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="clear"></div>
                <div class="leftbox">
                    <el-collapse v-model="activeName" accordion>
                        <el-collapse-item :title="item.CategoryName" :name="item.Id" v-for="(item,index) in HelpTableData" :key="index">
                            <div class="collapsetit" @click.prevent="tohelp(it.Id)" v-for="(it,ind) in item.HelpInfoList" :key="ind">{{it.HelpTitle}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="rightbox">
                    <div class="collapsecon">
                        <div class="search">
                            <input type="text" v-model="searchText" maxlength="50" placeholder="请输入关键字搜索问题" />
                            <div class="sbtn" @click.prevent="searchHelp()"><i class="iconfont">&#xe63b;</i> 搜 索</div>
                            <div class="clear"></div>
                        </div>
                        <div class="qlist">
                            <div class="list" v-if="listordetail==0">
                                <div class="qbox" @click.prevent="tohelp(item.Id)" v-for="(item,index) in HelpInfoData" :key="index">{{item.HelpTitle}}</div>
                            </div>
                            <div class="detail" v-if="listordetail==1">
                                <div class="tit">{{DetailData.HelpTitle}}</div>
                                <div class="con" v-html="DetailData.RichTextContent"></div>
                            </div>
                            <div class="nodata" v-show="nodata">
                                <i class="iconfont">&#xe67c;</i>
                                <span>未找到相关帮助信息</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
        
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            activeName:'',
            searchText:'',
            HelpInfoData:[],
            HelpTableData:[],
            listordetail:0,
            DetailData:{},
            nodata:false
        }
    },
    mounted(){
        this.GetHelpList();
    },
    methods:{
        tohelp(id){
            this.DetailData={};
            this.listordetail=1;//详情
            API.Web.GetHelpDetail({id:id}).then(res => {
                if(res.Code===0){
                    this.DetailData = res.Data;
                }
            });
        },
        searchHelp(){
            this.listordetail=0;//列表
            API.Web.GetHelpTopList({helpTitle:this.searchText}).then(res => {
                if(res.Code===0&&res.Data.length>0){
                    this.HelpInfoData = res.Data;
                    this.nodata=false;
                }
                else{
                    this.HelpInfoData = [];
                    this.nodata=true;
                }
            });
        },
        GetHelpList(){
            API.Web.GetHelpList({pageIndex:1,pageSize:1000}).then(res => {
                if(res.Code===0&&res.Data.Data.length>0){
                    this.nodata=false;
                    this.HelpInfoData = res.Data.Data;
                    API.Web.GetHelpCategoryList().then(res => {
                        if(res.Code===0&&res.Data.length>0){
                            this.activeName = res.Data[0].Id;
                            this.HelpTableData = res.Data;
                            this.HelpTableData.forEach(t=>{
                                t.HelpInfoList = this.HelpInfoData.filter(f=>f.HelpCategoryId===t.Id);
                            });
                        }
                    });
                }
                else{
                    this.nodata=true;
                }
            });
        }
    }
}
</script>
<style lang="less" scoped>
.helpbox{
    max-width: 1420px;
    margin: auto;
    user-select: none;
    .helpcon{
        margin: 10px 20px 30px;
        border: 1px solid #e7e6e6;
        min-height: 600px;
        background-color: #fff;
        .leftbox{
            width: 350px;
            float: left;
            .el-collapse{
                min-height: 542px;
                margin: 10px;
                border: 1px solid #e7e6e6;
                .collapsetit{
                    margin: 0px 20px;
                    line-height: 18px;
                    padding: 12px 0px;
                    color: #555;
                    font-size: 15px;
                    cursor: pointer;
                }
                .collapsetit:hover{
                    color: rgb(var(--themecolor));
                }
            }
        }
        .rightbox{
            width: calc(100% - 350px);
            float: right;
            .collapsecon{
                border: 1px solid #e7e6e6;
                margin: 10px;
                min-height: 542px;
                .search{
                    margin: 10px 20px 0;
                    height: 70px;
                    input{
                        margin-top: 10px;
                        width: calc(100% - 162px);
                        height: 42px;
                        border: 1px solid #ddd;
                        float: left;
                        line-height: 42px;
                        line-height: 1;
                        text-indent: 20px;
                        color: #555;
                        font-size: 15px;
                        border-radius: 3px;
                    }
                    .sbtn{
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 150px;
                        height: 44px;
                        float: left;
                        text-align: center;
                        line-height: 44px;
                        color: #fff;
                        font-size: 16px;
                        background-color: rgba(var(--themecolor),0.6);
                        border-radius: 3px;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                        cursor: pointer;
                    }
                    .sbtn:hover{
                        background-color: rgba(var(--themecolor),0.8);
                    }
                }
                .qlist{
                    margin: 0px 10px;
                    .qbox{
                        width: calc(100% - 20px);
                        height: 50px;
                        background-color: #f5f5f5;
                        margin: 20px 10px 0px;
                        line-height: 50px;
                        color: #333;
                        font-size: 16px;
                        text-indent: 20px;
                        overflow: hidden;
                        cursor: pointer;
                    }
                    .qbox:hover{
                        color: rgb(var(--themecolor));
                    }
                    .tit{
                        margin: 10px 20px 0;
                        color: #333;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        font-weight: bold;
                    }
                    .con{
                        margin: 20px 20px 30px;
                        color: #666;
                        font-size: 14px;
                        line-height: 24px;
                        overflow: hidden;
                    }
                    .nodata{
                        width: 100%;
                        min-height: 50px;
                        line-height: 50px;
                        color: #666;
                        font-size: 16px;
                        text-align: center;
                        letter-spacing: 1px;
                        user-select: none;
                        .iconfont{
                            font-size: 50px;
                            display: block;
                            margin-top: 40px;
                            color: #9b9b9b;
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="less">
.helpbox .helpcon .el-breadcrumb{
    margin: 15px 10px 5px;
    font-size: 15px;
}
.helpbox .helpcon .el-breadcrumb .iconfont{
    margin-right: 4px;
    font-size: 13px;
    float: left;
    margin-top: 2px;
}
.helpbox .helpcon .el-breadcrumb__inner.is-link{
    color: #666;
}
.helpbox .helpcon .el-breadcrumb__inner.is-link:hover{
    color: rgb(var(--themecolor));
}
.helpbox .helpcon .leftbox .el-collapse .el-collapse-item .el-collapse-item__header{
    text-indent: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.helpbox .helpcon .leftbox .el-collapse-item__arrow{
    transform: rotate(90deg);
}
.helpbox .helpcon .leftbox .el-collapse-item__arrow.is-active{
    transform: rotate(-90deg);
}
</style>